module.exports = {
  siteUrl: 'https://www.turbineproduction.com',
  sitemapPath: '/sitemap.xml',
  title: 'Turbine Production',
  description: "Capsule d'accompagnement artistique",
  author: '@oliviergenevest',
  locales: {fr:
    {
      path: 'fr',
      name: 'Français',
      default: true
    },
/*    en:
    {
      path: 'en',
      name: 'English'
    }*/
  },

};
