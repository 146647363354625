/* TODO : ajouter composant SEO avec les valeurs du site global */
import React from 'react';

import {IntlProvider} from 'react-intl'
// Messages
import en from '../translations/en.json';
import fr from '../translations/fr.json';
import styled from 'styled-components';


import { layoutTypes } from '../types/propTypes';

/*import {Text} from '../components/Elements'*/
const messages = { en, fr };



const NewsletterLayout = ({ children, pageContext, location }) => {



  return (
    <IntlProvider locale="fr" key="fr"  messages={messages["fr"]}  defaultLocale="fr" >
        {children}
        
    </IntlProvider>
   
  );
};

NewsletterLayout.propTypes = layoutTypes;

export default NewsletterLayout;
