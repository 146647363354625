import React from 'react'
import Layout from "./Layout"
import NewsletterLayout from "./NewsletterLayout"

const Index = ({ children, pageContext, location }) => {
    console.log("location LAYOUT INDEX:", location)
    if (pageContext.layout === "newsletter") {
      return <NewsletterLayout location={location}>{children}</NewsletterLayout>
    }
    return <Layout location={location} >{children}</Layout>
  }

  export default Index